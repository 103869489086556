<template>
  <div>
    <!-- Modal do Bootstrap -->
    <div
      class="modal fade"
      id="customConfirmModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="customConfirmLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="customConfirmLabel">Confirmação</h5>
          </div>
          <div class="modal-body">
            <p>{{ message }}</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal" @click="cancel">Cancelar</button>
            <button type="button" class="btn btn-blue" @click="confirm">Confirmar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";

export default {
  name: "CustomConfirm",
  data() {
    return {
      message: "",
      modalInstance: null,
      previousModal: null,
    };
  },
  mounted() {
    const modalElement = document.getElementById("customConfirmModal");
    this.modalInstance = new Modal(modalElement, {
      backdrop: "static",
      keyboard: false,
    });
  },
  methods: {
    open(message) {
      this.message = message;

      this.previousModal = document.querySelector(".modal.show");
      if (this.previousModal) {
        this.previousModalInstance = Modal.getInstance(this.previousModal);
        this.previousModalInstance.hide();
      }

      this.modalInstance.show();

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    confirm() {
      this.modalInstance.hide();
      if (this.resolve) this.resolve(true);
    },
    cancel() {
      this.modalInstance.hide();
      if (this.previousModalInstance) {
        this.previousModalInstance.show();
      }
      if (this.reject) this.reject(false);
    }
  }
};
</script>

<style scoped>
.modal-body {
  text-align: center;
}
</style>
