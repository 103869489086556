<template>
    <div v-if="$store.getters.getLoggedIn === true" class="d-flex top-header d-print-none">
        <div class="container-fluid">
            <div class="row">
                <div class="col-1 d-flex logo-header">
                    <i class="siscont icon_menu" type="button" v-if="$store.getters.isMobile" @click="toggleSidebar" id="openSideBar"/>
                    <router-link class="" to="/home"><img src="../../public/img/logo_header.png" alt="Cpaps"></router-link>
                </div>
                <div class="col-11 d-flex align-items-center justify-content-end">
                    <div class="box-enterprise">
                        <button class="btn btn-white d-flex align-items-center" id="btnOpenModalEnterprise" data-bs-toggle="modal" href="#modalEnterprise" role="button">
                            <i class="siscont icon_edit_square"/>
                            {{$store.state.enterprise.name}}
                        </button>
                    </div>
                    <div class="dropdown dropdown-account d-flex flex-sm-row">
                        <i class="siscont icon_user mt-3 ms-2 ms-sm-0 me-0 mt-sm-2 me-sm-3" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"/>
                        <div class="mt-0 mt-sm-2" id="noticeable-widget"></div>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li>
                                <a class="dropdown-item" @click="$store.dispatch('logout')">Sair</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <ModalEnterprise :modalTitle="getModalTitle" :txtBtnModal="gettxtBtnModal" />
    </div>
</template>
<script>
import {config} from '../../src/config'
import ModalEnterprise from '../components/ModalEnterprise.vue'
export default {
    name: "MyHeader",
    components: {
        ModalEnterprise,
    },
    methods:{
        toggleSidebar() {
            this.$emit('toggle-sidebar');
        },
    },
    computed: {
        getModalTitle() {
            return this.$store.getters.getEnterpriseId ? "Altere a empresa" : "Selecione a empresa"
        },
        gettxtBtnModal() {
            return this.$store.getters.getEnterpriseId ? "Atualizar" : "Selecionar"
        }
    },
    mounted() {
        this.$nextTick(function () {
            window.noticeable.render('widget', config.noticeable.iconWidgetId, {selector: '#noticeable-widget'});
        })
    },
  unmounted() {
    window.noticeable.destroy('widget', config.noticeable.iconWidgetId);
  }
}
</script>