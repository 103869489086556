<template>
  <div class="modal fade" id="messageModal" tabindex="-1" aria-labelledby="messageModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-body text-center">
          <i :class="modalIconClass" class="ms-2 modal-icon" />
          <p class="mt-3 mb-3 titles-scrum">{{ message }}</p>
          <button class="btn btn-blue" type="button" data-bs-dismiss="modal" aria-label="Close" id="btnCloseModal"
            @click="handleClose">
            OK
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap';

export default {
  computed: {
    message() {
      return this.$store.state.message;
    },
    messageType() {
      return this.$store.state.messageType;
    },
    redirectRoute() {
      return this.$store.state.redirectRoute;
    },
    modalIconClass() {
      let iconClass = '';

      switch (this.messageType) {
        case 'success':
          iconClass = 'siscont icon_check_circle';
          break;
        case 'error':
          iconClass = 'siscont icon_x_circle';
          break;
        case 'question':
          iconClass = 'siscont icon_question';
          break;
        default:
          iconClass = '';
          break;
      }

      return iconClass;
    }
  },
  watch: {
    '$route'() {
      this.closeAllModals();
      this.$store.dispatch('closeMessage');
    },
    message(newVal) {
      if (newVal) {
        this.showModal();
      } else {
        this.hideModal();
      }
    }
  },
  methods: {
    showModal() {
      this.closeAllModals();
      const modalElement = document.getElementById('messageModal');
      if (!this.modalInstance) {
        this.modalInstance = new Modal(modalElement);
        modalElement.addEventListener('hidden.bs.modal', this.onHiddenModal);
      }
      this.modalInstance.show();
    },
    hideModal() {
      if (this.modalInstance) {
        this.modalInstance.hide();
        this.modalInstance = null;
      }
    },
    onHiddenModal() {
      if (this.$store.state.reloadOnModalClose) {
        this.$router.go(0);
      } else if (this.$store.state.redirectRoute) {
        this.$router.push(this.$store.state.redirectRoute);
      } else if (this.$store.state.modalHistory.length > 0){
        this.$store.dispatch('closeMessage');
      }
      this.$store.commit('resetMessage');
    },
    waitForModal() {
      return new Promise(resolve => {
        const modalElement = document.getElementById('messageModal');
        modalElement.addEventListener('hidden.bs.modal', () => resolve(), { once: true });
      });
    },
    closeAllModals() {
      document.querySelectorAll('.modal').forEach(modal => {
        const instance = Modal.getInstance(modal);
        if (instance) {
          instance.hide();
        }
      });
      this.modalInstance = null;
    },
    handleClose() {
      const hasPreviousModal = this.$store.state.modalHistory.length > 0;
      if(hasPreviousModal){
        this.$store.dispatch('closeMessage');
      } else {
        this.hideModal();
      }
    }
  },
  created() {
    if (this.message) {
      this.showModal();
    }
  }
};
</script>

<style>
.modal-icon {
  font-size: 4rem;
}

.icon_check_circle::before {
  color: #49A971;
}

.icon_x_circle::before {
  color: red;
}
</style>