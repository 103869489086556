const CryptoJS = require("crypto-js");

const encryptId = function(param) {
    if (typeof param !== 'string') param = param.toString();
    return encodeURIComponent(CryptoJS.AES.encrypt(param, 'chama').toString());
};

const decryptId = function(param) {
    try {
        const decrypt = CryptoJS.AES.decrypt(decodeURIComponent(param), 'chama');
        return decrypt.toString(CryptoJS.enc.Utf8) || null;
    } catch (error) {
        console.error("Descriptografia falhou:", error);
        return null;
    }
};

export { encryptId, decryptId };
